@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Raleway&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
  --color-primary: #98251e;
  --color-secondary: #576079;
  --color-gold: #ab9c84;
  --color-black: #000;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-size: 18px;
  font-family: "Raleway", sans-serif;
}

h1,
h2 {
  font-family: "Playfair Display", serif;
  font-weight: normal;
}
.img-container,
.container {
  width: 55%;
  margin: 0 auto;
}

.sideDrawer {
  height: 100vh;
  background-color: var(--color-gold);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  color: black;
  font-weight: bold;
  z-index: 105;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.sideDrawer.open {
  transform: translateX(0);
}

nav a {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

nav a:hover {
  border-bottom: 2px solid black;
  margin-bottom: -2px;
}

.img-container {
  padding: 3rem;
  height: 100% !important;
}

@media (max-width: 900px) {
  .img-container,
  .container {
    width: 85%;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .img-container,
  .container {
    width: 95%;
    margin: 0 auto;
  }
}